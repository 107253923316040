import React from "react"
import * as Yup from "yup"
import FormikTextField from "../formik-fields/TextField"
import Select from "../formik-fields/Select"
import countryCodes from "./codes"

const mapCodesToOptions = codes =>
  codes.map(cc => ({ label: cc.name, value: cc.code }))

const requireStateCountries = countryCodes
  .filter(c => !!c.states && c.states.length > 0)
  .map(c => `<${c.code}>`)

const countryOptions = mapCodesToOptions(countryCodes)

const getStateOptions = code => {
  return mapCodesToOptions(
    countryCodes.find(cc => cc.code === code).states || []
  )
}

export const getAddressValidation = () => ({
  first_name: Yup.string().required(),
  last_name: Yup.string().required(),
  address1: Yup.string().required(),
  city: Yup.string().required(),
  country_code: Yup.string().required(),
  state_code: Yup.string().when("country_code", {
    is: val => {
      return requireStateCountries.indexOf(`<${val}>`) > -1
    },
    then: Yup.string().required()
  }),
  zip: Yup.string().required()
})

const DEFAULT_COUNTRY = "US"

export class AddressForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { stateOptions: [] }
  }

  componentDidMount() {
    this.setStateOptions(
      this.props.initialValues.country_code || DEFAULT_COUNTRY
    )
  }

  setStateOptions = code => {
    if (code !== "") {
      this.setState({ stateOptions: getStateOptions(code) })
    } else {
      this.setState({ stateOptions: [] })
      this.props.onChange({ state_code: "" })
    }
  }

  handleCountryChange = e => {
    this.setStateOptions(e.target.value)
  }

  render() {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <FormikTextField
          name="first_name"
          label="First name"
          style={{ flexGrow: 1 }}
        />
        <FormikTextField
          name="last_name"
          label="Last Name"
          style={{ flexGrow: 1 }}
        />
        <FormikTextField label="Address" name="address1" fullWidth />
        <FormikTextField label="Address 2" name="address2" fullWidth />
        <FormikTextField id="city" label="City" name="city" fullWidth />
        <Select
          id="country"
          name="country_code"
          options={countryOptions}
          label="Country"
          onChange={this.handleCountryChange}
          fullWidth
        />
        {this.state.stateOptions.length ? (
          <Select
            name="state_code"
            fullWidth
            label="State"
            options={this.state.stateOptions}
            style={{ width: "auto" }}
            fullWidth
          />
        ) : null}
        <FormikTextField name="zip" label="Postal Code" fullWidth />
      </div>
    )
  }
}

AddressForm.defaultProps = {
  initialValues: {}
}

export default AddressForm

import React from "react"
import Button from "../ui/Button"
import Cart from "./Cart"
import { gold } from "../ui/theme"
import { CheckoutFooter } from "./ui"
import useCart from "../../hooks/useCart"

const CheckoutStart = ({ onComplete }) => {
  const { itemCount } = useCart()
  return (
    <React.Fragment>
      <h3>Your Order</h3>
      <Cart />
      {itemCount > 0 ? (
        <CheckoutFooter>
          <Button
            label="Checkout"
            labelStyle={{ fontFamily: "Gotham Pro", fontSize: 15 }}
            style={{ marginLeft: "auto" }}
            onClick={() => onComplete({})}
            size="large"
            primary
          />
        </CheckoutFooter>
      ) : null}
    </React.Fragment>
  )
}

export default CheckoutStart

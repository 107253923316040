import React, { useState, useEffect } from "react"
import styled from "styled-components"
import * as Icon from "react-feather"
import Testimonial from "../promo/Testimonial"
import testimonials from "../content/testimonials"
import { useUITheme } from "../ui/theme"

const Container = styled.div`
  width: 100%;
  color: ${p => p.theme.palette.text.primary};
  /* background-color: ${p => p.theme.palette.background.default}; */
`

const GBox = styled.div`
  padding: 8px;
  text-align: center;
  color: ${p => p.foregroundColor};
  width: 100%;
  min-width: 300px;
  h1,
  h2 {
    font-size: 11px;
    margin: 0px;
    text-transform: uppercase;
  }

  p {
    font-size: 11px;
  }

  h1 {
    font-size: 24px;
  }
`

const TestimonialRotatorContainer = styled.div`
  font-size: 90%;
  margin: 0px auto;
  padding: 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  max-width: 1000px;
`

const FactsContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: space-between;
  width: 100%;
  margin: 0px auto;
  max-width: 1000px;

  @media (max-width: 700px) {
    flex-wrap: wrap;
  }
`

const TestimonialRotator = () => {
  const [index, setIndex] = useState(0)
  const [theme] = useUITheme()

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(index => (index < testimonials.length - 1 ? index + 1 : 0))
    }, 5000)
    return () => clearInterval(interval)
  }, [])
  const testimonial = testimonials[index]
  return (
    <TestimonialRotatorContainer>
      <Testimonial
        {...testimonial}
        color={theme.palette.text.primary}
        backgroundColor={theme.palette.background.paper}
      />
    </TestimonialRotatorContainer>
  )
}

const Assure = ({ foregroundColor, backgroundColor, style }) => {
  return (
    <div style={{ maxWidth: 1000, width: "100%", ...style }}>
      <Container>
        <FactsContainer>
          <GBox>
            <h1>FREE Shipping</h1>
            <h2>To anywhere in the U.S.</h2>
          </GBox>
          <GBox>
            <h1>100% Guaranteed</h1>
            <h2>Or your money back</h2>
          </GBox>
          <GBox>
            <h1>
              <Icon.Lock width={16} /> Secure
            </h1>
            <h2>Your privacy is, well, yours</h2>
          </GBox>
        </FactsContainer>
      </Container>
      <TestimonialRotator
        foregroundColor={foregroundColor}
        backgroundColor={backgroundColor}
      />
    </div>
  )
}

export default Assure

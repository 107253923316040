import React, { useState, useEffect } from "react"
import Radio from "@material-ui/core/Radio"
import Button from "../ui/Button"
import styled from "styled-components"
import { useQuery } from "@apollo/react-hooks"
import { gql } from "apollo-boost"
import { gold } from "../ui/theme"
import { CheckoutFooter, Loading } from "./ui"

const Container = styled.div`
  color: ${p => p.theme.palette.text.primary};
`

const ShippingItemContainer = styled.div`
  padding: 10px 0px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  color: ${p => p.theme.palette.text.primary};
  border-bottom: 1px solid ${p => p.theme.palette.divider};
  background-color: ${p => p.theme.palette.background.paper};
`

const PriceLabel = styled.div`
  margin-left: auto;
  font-weight: bold;
`

const GET_FEES = gql`
  query Fees($recipient: AddressInput!, $items: [ItemInput!]!) {
    fees(recipient: $recipient, items: $items) {
      taxAmount
      baseAmount
      shippingAmount
      shippingID
      total
      shippingOptions {
        id
        label
        description
        rate
      }
    }
  }
`

const ShippingSelector = ({ options, onSelect, value }) => {
  const handleSelect = option => () => {
    onSelect(option)
  }

  return (
    <div>
      {options.map((option, i) => {
        const id = `option-${i}`
        const selected = value ? option.id === value.id : false
        return (
          <ShippingItemContainer key={id} onClick={handleSelect(option)}>
            <Radio id={id} color="default" checked={selected} />
            <div>
              <strong style={{ margin: 0 }}>{option.label}</strong>
              <p style={{ margin: 2 }}>{option.description}</p>
            </div>
            {option.rate > 0 ? (
              <PriceLabel>${option.rate}</PriceLabel>
            ) : (
              <PriceLabel>FREE</PriceLabel>
            )}
          </ShippingItemContainer>
        )
      })}
    </div>
  )
}

const SelectShipping = ({ onComplete, items, recipient, order }) => {
  const { loading, error, data } = useQuery(GET_FEES, {
    variables: {
      recipient,
      items
    }
  })
  const [shippingMethod, setShippingMethod] = useState(order.shippingMethod)

  useEffect(() => {
    if (data && !shippingMethod) {
      setShippingMethod(data.fees.shippingOptions[0])
    }
  }, [data, shippingMethod])

  const handleSelect = shippingMethod => {
    setShippingMethod(shippingMethod)
  }

  const handleComplete = options => () => {
    onComplete({ shippingMethod })
  }

  if (loading) return <Loading />
  if (error) return `Error! ${error.message}`

  return (
    <Container>
      <h3>Select shipping method</h3>
      <ShippingSelector
        options={data.fees.shippingOptions}
        onSelect={handleSelect}
        value={shippingMethod}
      />
      <p style={{ fontStyle: "italic" }}>Fullfilment usually takes 2-5 days</p>
      <CheckoutFooter>
        <Button
          label="Continue to payment method"
          onClick={handleComplete(data.fees.shippingOptions)}
          type="submit"
          type="contained"
          primary
        />
      </CheckoutFooter>
    </Container>
  )
}

export default SelectShipping

import React from 'react'
import MUITextField from '@material-ui/core/TextField'
import { Field } from 'formik'

const TextField = ({ name, label, placeholder, ...rest }) => (
  <Field
    name={name}
    render={({ field, form }) => {
      const error = !!form.errors[name]
      return (
        <MUITextField
          label={label}
          placeholder={placeholder}
          margin="dense"
          error={error}
          InputLabelProps={{
            shrink: true,
          }}
          {...field}
          {...rest}
        />
      )
    }}
  />
)

export default TextField

import { IconButton } from "@material-ui/core"
import { navigate } from "gatsby"
import React, { useState } from "react"
import * as Icon from "react-feather"
import styled from "styled-components"
import useCart from "../../hooks/useCart"
import useOrder from "../../hooks/useOrder"
import { useWindowSize } from "../../hooks/useWindowSize"
import Message from "../ui/Message"
import Assure from "./Assure"
import CheckoutStart from "./CheckoutStart"
import CheckoutStepper from "./CheckoutStepper"
import CheckoutSumary from "./CheckoutSummary"
import InfoAndShippingAddress from "./InfoAndShippingAddress"
import OrderComplete from "./OrderComplete"
import PaymentMethod from "./PaymentMethod"
import Preview from "./Preview"
import SelectShipping from "./SelectShipping"

const Wrap = styled.div`
  width: 100%;
  min-height: 100vh;
  height 100%;
  background-color: ${p => p.theme.palette.background.default};
`

const Container = styled.div`
  max-width: 1200px;
  min-height: calc(100% - 300px);
  margin: 0px auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  padding-bottom: 20px;
  background-color: ${p => p.theme.palette.background.default};

  @media (max-width: 745px) {
    flex-wrap: wrap;
  }

  h3 {
    color: ${p => p.theme.palette.text.primary};
  }
`

const PreviewContainer = styled.div`
  flex-shrink: 0;
  width: ${p => p.previewSize}px;
  height: ${p => p.previewHeight}px;
  margin: 0px auto;
  justify-content: center;
  display: flex;
  align-items: center;
`

const CheckoutContainer = styled.div`
  padding: 0px 20px;
  flex-grow: 1;
  /* border-bottom: 1px solid #ddd; */
  h4 {
    margin: 0px;
    margin-bottom: 5px;
  }

  @media (max-width: 745px) {
    width: 100%;
  }
`

const Footer = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  border-top: 1px solid ${p => p.theme.palette.divider};
  background-color: ${p => p.theme.palette.background.paper};
`
const Help = styled.div`
  padding: 10px 0px;
  background-color: ${p => p.theme.palette.background.paper};
  color: ${p => p.theme.palette.text.primary};
  text-align: center;
  a {
    color: ${p => p.theme.palette.text.primary};
  }
`

const franceAddress = {
  first_name: "Mark",
  last_name: "Johnson",
  address1: "4 place Maxence Van Der Meersch",
  city: "MARCQ-EN-BAROEIL",
  country_code: "FR",
  zip: "59700"
}

const usAddress = {
  first_name: "Mark",
  last_name: "Johnson",
  address1: "34 N Garden Cir",
  city: "Greenville",
  country_code: "US",
  state_code: "SC",
  zip: "29615"
}

const initialData = {
  email: "",
  shippingMethod: null,
  billingSameAsShipping: true,
  paymentToken: null,
  shipping: {},
  billing: {}
}

const Checkout = props => {
  const [order, updateOrder] = useOrder()
  const [step, setStep] = useState(0)
  const { width: containerWidth } = useWindowSize()
  const { cartItems } = useCart()

  const previewSize = Math.min(420, containerWidth)

  const renderStep = () => {
    const stepProps = {
      order,
      onComplete: data => {
        updateOrder(data)
        setStep(step + 1)
      }
    }
    switch (step) {
      case 0:
        return <CheckoutStart {...stepProps} />
      case 1:
        return <InfoAndShippingAddress {...stepProps} />
      case 2:
        const items = cartItems.map(item => ({
          framed: item.posterData.framed,
          quantity: item.quantity,
          data: item.posterData
        }))
        return (
          <SelectShipping
            recipient={order.shippingAddress}
            items={items}
            {...stepProps}
          />
        )
      case 3:
        return <PaymentMethod {...stepProps} />
      default:
        return null
    }
  }

  const handleSelectStep = s => {
    setStep(s)
  }

  return (
    <Wrap>
      <Message />
      <IconButton onClick={() => navigate("/editor")}>
        <Icon.ChevronLeft />
        <span style={{ fontSize: 14 }}>Back</span>
      </IconButton>
      <CheckoutStepper
        style={{ backgroundColor: "transparent", maxWidth: 1000 }}
        step={step}
        onSelectStep={handleSelectStep}
        data={order}
      />
      <Container>
        <PreviewContainer previewSize={previewSize}>
          <Preview previewSize={previewSize} />
        </PreviewContainer>
        <CheckoutContainer previewSize={previewSize}>
          {!order.completedOrder ? (
            <>
              <CheckoutSumary step={step} order={order} onSelect={setStep} />
              {renderStep()}
            </>
          ) : (
            <OrderComplete
              order={order.completedOrder}
              shippingMethod={order.shippingMethod}
            />
          )}
        </CheckoutContainer>
      </Container>
      <Footer>
        <Assure />
      </Footer>

      <Help>
        Need <a href="mailto:mark@tiltmaps.com?subject=TiltMaps help"> help</a>?
      </Help>

      {/* <AppFooter backgroundColor={'#000'} foregroundColor={gold} /> */}
    </Wrap>
  )
}

export default Checkout

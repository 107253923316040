import React, { Component } from "react"
import Layout from "../components/layout"
import Checkout from "../components/commerce/Checkout"

class CheckoutPage extends Component {
  render() {
    return (
      <Layout>
        <Checkout />
      </Layout>
    )
  }
}

export default CheckoutPage

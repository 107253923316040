import React from 'react'
import { NativeSelect, FormControl, InputLabel } from '@material-ui/core'
import { Field } from 'formik'

class SelectField extends React.Component {
  handleChange = field => e => {
    field.onChange(e)
    if (this.props.onChange) this.props.onChange(e)
  }
  render() {
    const { name, options, required, label, fullWidth, ...rest } = this.props
    return (
      <Field
        name={name}
        render={({ field, form }) => {
          const error = form.errors[name]
          return (
            <FormControl margin="dense" fullWidth={fullWidth}>
              <InputLabel shrink={true} required={required}>
                {label}
              </InputLabel>
              <NativeSelect
                {...field}
                {...rest}
                error={error}
                onChange={this.handleChange(field)}
                native
              >
                <option value="" />
                {options.map((op, i) => (
                  <option key={i} value={op.value}>
                    {op.label}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          )
        }}
      />
    )
  }
}

export default SelectField

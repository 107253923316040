import React, { useState } from "react"

import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"

const CheckoutStepper = ({ step, onSelectStep, style }) => {
  // const finalTotal = shippingMethod ? shippingMethod.rate + total : total
  const steps = [
    { label: `Order` },
    { label: "Info." },
    { label: `Shipping` },
    { label: `Payment` }
  ]

  return (
    <Stepper
      activeStep={step}
      style={{
        ...style,
        backgroundColor: "transparent",
        margin: "0px auto",
        flexWrap: "wrap"
      }}
    >
      {steps.map(({ label }, i) => {
        const canNavigate = false // i < step
        const style = canNavigate
          ? { textDecoration: "underline", cursor: "pointer" }
          : { fontSize: 12 }
        return (
          <Step key={label} disabled={i > step}>
            <StepLabel
              style={{ ...style, marginBottom: 4 }}
              onClick={canNavigate ? onSelectStep(i) : () => {}}
            >
              {label}
            </StepLabel>
          </Step>
        )
      })}
    </Stepper>
  )
}

export default CheckoutStepper

import React from "react"
import styled from "styled-components"
import Poster from "./Poster"
import { baseSize } from "../poster/data"
import { useWindowSize } from "../../hooks/useWindowSize"

const PreviewContainer = styled.div`
  position: relative;
  width: ${p => (p.width ? `${p.width}px` : "100%")};
  height: ${p => (p.height ? p.height : 450)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 100%;
  background-position: 50%;
  overflow: hidden;
`

const PosterWrap = styled.div`
  position: relative;
  /* top: ${p => 27 * p.scale}px; */
  border: 10px solid red;
  left: -1px;
`

const PosterPreview = ({
  width,
  height,
  state,
  transitionIn,
  posterOnly,
  overlayImage,
  framed,
  style
}) => {
  const baseHeight = baseSize * (state.size === "18x18" ? 1 : 24 / 18)
  let scaleDown = state.size === "18x18" ? 0.678 : 0.519
  const posterStyles =
    state.size === "18x24" ? { left: -2, top: -12 } : { left: -3, top: 16 }
  const windowSize = useWindowSize()
  const previewWidth = Math.min(windowSize.width, baseSize)
  if (posterOnly)
    return (
      <div
        style={{
          ...style,
          position: "relative",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height,
          width
        }}
      >
        <Poster
          {...state}
          scale={1}
          scaleDown={scaleDown}
          width={baseSize}
          height={baseHeight}
          transitionIn={transitionIn}
          overlayImage={overlayImage}
          style={posterStyles}
          readonly
        />
      </div>
    )

  return (
    <PreviewContainer width={previewWidth} height={baseSize} style={style}>
      <PosterWrap scale={1}>
        <Poster
          {...state}
          scale={previewWidth / 450}
          framed={framed}
          scaleDown={scaleDown}
          width={baseSize}
          height={baseHeight}
          transitionIn={transitionIn}
          overlayImage={overlayImage}
          style={posterStyles}
          readonly
        />
      </PosterWrap>
      {/* {image ? (
        <img
          src={image}
          style={{
            display: "block",
            position: "absolute",
            width: "100%",
            top: 0,
            left: 0
          }}
        />
      ) : null} */}
    </PreviewContainer>
  )
}

PosterPreview.defaultProps = {
  width: 500,
  height: 500,
  size: 500,
  transitionIn: false,
  posterOnly: false,
  overlayImage: true,
  framed: false
}

export default PosterPreview

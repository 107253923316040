import React from "react"
import * as Icon from "react-feather"
import styled from "styled-components"
import pluralize from "pluralize"
import { gold, useUITheme } from "../ui/theme"
import PosterPreview from "../poster/PosterPreview"
import { toWords } from "./utils"
import capitalize from "lodash/capitalize"
import useCart from "../../hooks/useCart"
import usePoster from "../../hooks/usePoster"
import Poster from "../poster/Poster"
import { baseSize } from "../poster/data"
import { getScaleDown } from "../poster/utils"

const PreviewContainer = styled.div`
  /* max-width: ${p => p.previewSize}px;
  min-height: ${p => p.previewSize}px;
  padding: 20px; */
  /* border: 1px solid ${p => p.theme.palette.divider}; */
  /* background-color: ${p => p.theme.palette.background.paper}; */
  max-width: ${p => p.previewSize + p.previewSize / 18}px;  
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  @media (max-width: 740px) {
    width: 100%;
  }
`

const PreviewInfo = styled.div`
  position: relative;
  color: ${p => p.theme.palette.text.primary};
  padding: 10px 20px;
  font-size: 16px;
  top: -40px;
`

const PreviewInfoItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0px;
  svg {
    display: block;
    flex-shrink: 0;
    width: 18px;
    margin-right: 20px;
  }
  p {
    display: block;
    margin: 0px;
    margin-bottom: 5px;
  }
`

const PreviewLabel = styled.div`
  position: absolute;
  font-size: 14px;
  top: 0px;
  right: 0px;
  z-index: 10;
  padding: 8px 16px;
  text-align: center;
  color: ${gold};
  background-color: #000;
`

const Preview = ({ previewSize }) => {
  const { selectedItem } = useCart()
  const [poster] = usePoster()
  const previewPosterState = selectedItem ? selectedItem.posterData : poster
  let infoItems = []
  const framed = selectedItem
    ? selectedItem.posterData.framed
    : previewPosterState.framed || false

  const [w, h] = previewPosterState.size.split("x")

  if (selectedItem) {
    const { quantity } = selectedItem
    infoItems.push(() => (
      <span>
        {capitalize(toWords(quantity))} {`${w}" x ${h}"`} museum-quality{" "}
        {pluralize("print", quantity)} on thick, durable, matte paper.`
      </span>
    ))
    if (framed)
      infoItems.push(
        () =>
          `Framed with a .75” thick but lightweight black alder, semi-hardwood frame. Acrylite front protector. Hanging hardware included.`
      )

    if (previewPosterState.banana) {
      infoItems.push(
        () => `Banana for Scale™️ is not included. Try your local supermarket.`
      )
    }
  }

  const height =
    previewPosterState.size === "18x24" ? baseSize * (24 / 18) : baseSize

  return (
    <PreviewContainer previewSize={previewSize} framed={framed}>
      <div
        style={{
          position: "relative",
          top: previewPosterState.size === "18x24" ? -55 : -40
        }}
      >
        <Poster
          {...previewPosterState}
          framed={framed}
          width={baseSize}
          height={height}
          scaleDown={getScaleDown(previewSize, previewPosterState)}
          readonly
        />
      </div>
      <PreviewInfo>
        {infoItems.map((info, i) => {
          return (
            <PreviewInfoItem key={i}>
              {i < 2 ? <Icon.CheckSquare /> : <Icon.Square />}
              <p>{info()}</p>
            </PreviewInfoItem>
          )
        })}
      </PreviewInfo>
    </PreviewContainer>
  )
}

export default Preview

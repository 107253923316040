import React, { useState } from "react"
import color from "color"
import styled from "styled-components"
import { getPosterLabel } from "./utils"

import * as Icon from "react-feather"
import { navigate } from "gatsby"

import {
  Select,
  Checkbox,
  FormControlLabel,
  IconButton
} from "@material-ui/core"
import { gold } from "../ui/theme"
import usePoster from "../../hooks/usePoster"
import { getProductInfo } from "../../hooks/useCart"

const Container = styled.div`
  background-color: ${p => p.theme.palette.background.paper};
  margin-bottom: 10px;
  border: 1px solid
    ${p =>
      !p.isSelected
        ? p.theme.palette.divider
        : color(p.theme.palette.divider)
            .alpha(0.8)
            .toString()};
`

const LineItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  position: relative;
  padding: 10px 14px;

  cursor: pointer;
  color: ${p => p.theme.palette.text.primary};

  h3 {
    margin: 0px;
  }

  p {
    color: #333;
    margin: 0px;
  }
`

const LineItemControls = styled.div`
  background-color: ${p => p.theme.palette.background.paper};
  border-top: 1px solid ${p => p.theme.palette.divider};
  display: flex;
  width: 100%;
  button {
    font-size: 1em;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 5px;
      color: ${p => p.theme.palette.text.primary};
    }
  }
`

const QuantiySelect = ({ item, onChange, foregroundColor }) => {
  const options = Array.from(Array(10).keys()).map(i => ({
    label: i + 1,
    value: i + 1
  }))
  return (
    <div style={{ marginLeft: "auto", disaply: "flex", alignItems: "center" }}>
      <label>Quantity:</label>
      <Select
        value={item.quantity}
        onChange={onChange}
        placeHolder="Quantity"
        color={"default"}
        style={{ width: 40, marginRight: 10 }}
        native
      >
        {options.map(op => (
          <option key={op.value} value={op.value}>
            {op.label}
          </option>
        ))}
      </Select>
    </div>
  )
}

const CartControl = styled(IconButton)`
  font-size: 12px;
  color: ${p => p.theme.palette.text.primary};
  svg {
    font-size: 12px;
  }
`

const CartItem = ({
  item,
  foregroundColor,
  backgroundColor,
  isSelected,
  selectItem,
  removeItem,
  toggleFrame,
  setQuantity,
  index
}) => {
  const [poster, updatePoster] = usePoster()
  const handleQuantityChange = e => {
    const quantity = parseInt(e.target.value)
    if (quantity === 0) {
      removeItem(item.id)
    } else {
      setQuantity(item.id, quantity)
    }
  }

  const handleEditItem = item => {
    updatePoster(item.posterData, true)
    navigate("/editor")
  }

  return (
    <Container isSelected={isSelected}>
      <LineItem
        isSelected={isSelected}
        foregroundColor={foregroundColor}
        backgroundColor={backgroundColor}
      >
        <div
          style={{ display: "flex", width: "100%", alignItems: "center" }}
          onClick={() => selectItem(index)}
        >
          <div>
            <div>
              <h3>{getPosterLabel(item.posterData)}</h3>
              {item.description ? <p>{item.description}</p> : null}
            </div>

            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ paddingRight: 0 }}
                    color="default"
                    checked={item.posterData.framed === true}
                  />
                }
                label={`Frame it (+$${getProductInfo(item).framePrice})`}
                onChange={() => toggleFrame(item)}
              />
            </div>
          </div>
        </div>
        <QuantiySelect
          foregroundColor={foregroundColor}
          backgroundColor={backgroundColor}
          item={item}
          onChange={handleQuantityChange}
        />
        <div style={{ marginLeft: "auto", fontWeight: "bold" }}>
          ${item.price * item.quantity}
        </div>
      </LineItem>
      {isSelected ? (
        <LineItemControls isSelected={isSelected}>
          <CartControl
            // style={{ color: gold }}
            onClick={() => handleEditItem(item)}
          >
            <Icon.Edit /> Edit
          </CartControl>
          <CartControl style={{ marginLeft: "auto" }}>
            <Icon.Trash onClick={() => removeItem(item.id)} />
          </CartControl>
        </LineItemControls>
      ) : null}
    </Container>
  )
}

export default CartItem

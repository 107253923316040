import React from "react"
import * as Yup from "yup"
import { FormGroupContainer, CheckoutFooter } from "./ui"
import { Formik, Form } from "formik"
import { AddressForm, getAddressValidation } from "./AddressForm"
import FormikTextField from "../formik-fields/TextField"
import { gold } from "../ui/theme"

import Button from "../ui/Button"

const cleanShipping = address => {
  if (address.country_code !== "US") {
    delete address["state_code"]
  }
  return address
}

const schema = {
  email: Yup.string()
    .email()
    .required(),
  ...getAddressValidation()
}

const InfoAndShippingAddress = ({
  onComplete,
  order: { email, shippingAddress }
}) => {
  const handleSubmit = values => {
    let { email, ...shipping } = values
    const shippingAddress = cleanShipping(shipping)
    onComplete({ email, shippingAddress })
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={Yup.object(schema)}
      initialValues={{ email, ...shippingAddress }}
      render={({ values, handleSubmit, isSubmitting, handleReset }) => {
        return (
          <Form>
            <h3>Enter contact information</h3>
            <FormGroupContainer>
              <FormikTextField
                name="email"
                label="Email Address"
                placeholder="Email"
                fullWidth
              />
            </FormGroupContainer>
            <h3>Enter shipping address</h3>
            <FormGroupContainer>
              <AddressForm initialValues={shippingAddress} />
            </FormGroupContainer>
            <CheckoutFooter>
              <Button
                label="Continue to shipping method"
                type="submit"
                type="contained"
                primary
              />
            </CheckoutFooter>
          </Form>
        )
      }}
    />
  )
}

export default InfoAndShippingAddress

import React from "react"
import styled from "styled-components"

const ErrorContainer = styled.div`
  background-color: ${p => p.theme.palette.background.paper};
  color: ${p => p.theme.palette.text.primary};
  padding: 10px;
  border: 1px solid ${p => p.theme.palette.divider};
  border-radius: 4px;
  a {
    color: ${p => p.theme.palette.text.primary};
  }
  h3 {
    text-align: center;
  }
`

const Error = ({ message, showSupportLink }) => {
  return (
    <ErrorContainer>
      <>
        <h3 style={{ fontSize: 18, margin: 0, marginBottom: 5 }}>🤦‍ Oops!</h3>
        <span>{message}</span>
        {showSupportLink ? (
          <span>
            You may contact support <a href="mailto:mark@tiltmaps.com">here</a>.
          </span>
        ) : null}
      </>
    </ErrorContainer>
  )
}

Error.defaultProps = {
  showSupportLink: true
}

export default Error

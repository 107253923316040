import React from "react"
import styled from "styled-components"
import CircularProgress from "@material-ui/core/CircularProgress"

/*
MuiTypography-root MuiFormControlLabel-label MuiTypography-body1
*/
export const FormGroupContainer = styled.div`
  background-color: ${p => p.theme.palette.background.paper};
  padding: 16px;
  border: 1px solid ${p => p.theme.palette.divider};
  margin-bottom: 10px;

  .MuiFormControlLabel-label {
    color: ${p => p.theme.palette.text.primary};
  }
`

const LoadingContainer = styled.div`
  width: 100%;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Loading = ({ style }) => {
  return (
    <LoadingContainer style={style}>
      <CircularProgress />
    </LoadingContainer>
  )
}

export const CheckoutFooter = styled.footer`
  display: flex;
  justify-content: center;
  padding: 10px;
  align-items: center;
  button {
    margin-left: auto;
  }
`

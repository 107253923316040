import React from "react"
import styled from "styled-components"
import numeral from "numeral"
import useCart from "../../hooks/useCart"

const SummaryItemContainer = styled.div`
  /* border: 1px solid #ddd; */
  font-size: 16px;
  display: flex;
  height: 40px;
  align-items: center;
  padding: 10px;
  /* background-color: #fff; */
  color: ${p => p.theme.palette.text.primary};
  border-bottom: 1px solid ${p => p.theme.palette.divider};
  label {
    width: 60px;
  }
  a {
    margin-left: auto;
    color: ${p => p.theme.palette.text.primary};
  }
`

const SummaryItem = ({ label, value, step, onSelect }) => {
  return (
    <SummaryItemContainer>
      <label>{label}</label>
      <span>{value}</span>
      <a href="#" onClick={() => onSelect(step)}>
        Change
      </a>
    </SummaryItemContainer>
  )
}

const CheckoutSumary = ({
  step,
  onSelect,
  order: { shippingMethod, shippingAddress, email }
}) => {
  const { cartItems, total } = useCart()
  const addressLabel = shippingAddress ? `${shippingAddress.address1}...` : ""
  const orderLabel = `${cartItems.length} poster${
    cartItems.length !== 1 ? "s" : ""
  } ($${total})`

  const methodLabel = shippingMethod
    ? `${shippingMethod.label} - ${numeral(shippingMethod.rate).format(
        "$0,0.00"
      )}`
    : null

  const handleSelect = stepNo => () => onSelect(stepNo)

  return (
    <div>
      {step > 0 ? (
        <SummaryItem
          label="Order"
          value={orderLabel}
          step={0}
          onSelect={handleSelect(0)}
        />
      ) : null}
      {step > 1 ? (
        <SummaryItem
          label="Contact"
          value={email}
          step={1}
          onSelect={handleSelect(1)}
        />
      ) : null}
      {step > 1 ? (
        <SummaryItem
          label="Ship to"
          value={addressLabel}
          step={1}
          onSelect={handleSelect(1)}
        />
      ) : null}
      {step > 2 ? (
        <SummaryItem
          label="Method"
          value={methodLabel}
          step={2}
          onSelect={handleSelect(2)}
        />
      ) : null}
    </div>
  )
}

export default CheckoutSumary
